// Image Manager
$(document).delegate('a[data-toggle=\'image\']', 'click', function(e) {
    e.preventDefault();

    $('.popover').popover('hide', function() {
        $('.popover').remove();
    });

    var element = this;

    $(element).popover({
        container: 'body',
        sanitize: false,
        html: true,
        placement: 'right',
        trigger: 'manual',
        content: function() {
            return "<button id=\"button-image\" class=\"btn btn-primary\"><i class=\"fa fa-pencil-alt\"></i></button> <button type=\"button\" id=\"button-clear\" class=\"btn btn-danger\"><i class=\"fa fa-trash-alt\"></i></button>";
        }
    });

    $(element).popover('show');

    $('#button-image').on('click', function(e) {
        e.preventDefault();
        $('#modal-image').remove();

        var directory = $(element).data('directory');
        
        var url = 'filemanager/list?target=' + $(element).parent().find('input').attr('id') + '&thumb=' + $(element).attr('id');
        if(directory){
            url += '&directory=' + directory
        }

        $.ajax({
            url: url,
            dataType: 'html',
            beforeSend: function() {
                $('#button-image i').replaceWith('<i class="fa fa-circle-notch fa-spin"></i>');
                $('#button-image').prop('disabled', true);
            },
            complete: function() {
                $('#button-image i').replaceWith('<i class="fa fa-pencil-alt"></i>');
                $('#button-image').prop('disabled', false);
            },
            success: function(html) {
                $('body').append('<div id="modal-image" class="modal">' + html + '</div>');

                $('#modal-image').modal('show');
            },
            error: function(xhr, status, error){
                console.log(this);
            }
        });

        $(element).popover('hide', function() {
            $('.popover').remove();
        });
    });

    $('#button-clear').on('click', function(e) {
        e.preventDefault();
        $(element).find('img').attr('src', $(element).find('img').attr('data-placeholder'));

        $(element).parent().find('input').attr('value', '');

        $(element).popover('hide', function() {
            $('.popover').remove();
        });
    });
});
