require('./bootstrap');
require('jquery-ui/ui/widgets/autocomplete.js');
window.numeral = require('numeral');

/*$("form :input[name^='search']").focusout(function(){
    var input = $(this);
    var form = input.parents('form');
    form.submit();
});*/

$('form select[name^=\'search\']').on('keydown', function (e) {
    if (e.keyCode == 13) {
        var input = $(this);
        var form = input.parents('form');
        form.submit();
    }
});

$(document).delegate('[data-format-value]', 'onfocus, blur, change', function () {
    var input = $(this);
    var value = input.val();
    var format = input.data('formatValue');
    var string = numeral(value).format(format);
    input.val(string);
});

$(document).delegate('#document-totals', 'calculate', function () {
    var subTotal = $('#document-totals [data-total="sub-total"]');
    var total = $('#document-totals [data-total="total"]');
    var tax = $('#document-totals [data-total="tax"]');

    var rows = $('#documentLines > tbody > tr > td > :input[name^="lines["][name$="][total"], #documentLines > tbody > tr > td > :input[name^="lines["][name$="][total_cost]"]');

    var totals = rows.map(function () {
        var value = $(this).val();
        var numeralValue = window.numeral(value);
        return numeralValue.value();
    }).get();
    const totalSum = totals.reduce((a, b) => a + b, 0)
    var totalNumeral = numeral(totalSum);
    var subTotalNumeral = totalNumeral.clone();
    subTotalNumeral.divide(1.15)

    var taxNumeral = totalNumeral.clone();
    taxNumeral.subtract(subTotalNumeral.value());

    subTotal.text('R ' + subTotalNumeral.format('0.00'));
    total.text('R ' + totalNumeral.format('0.00'));
    tax.text('R ' + taxNumeral.format('0.00'));
});

$(document).delegate('#documentLines > tbody > tr > td > :input[name^="lines["][name$="][total_cost]"]', 'change', function () {
    $('#document-totals').trigger('calculate');
});

$(document).delegate('#documentLines > tbody > tr > td > :input[name^="lines["][name$="][quantity]"]' +
    ', #documentLines > tbody > tr > td > :input[name^="lines["][name$="][price]"]' +
    ', #documentLines > tbody > tr > td > :input[name^="lines["][name$="][cost]"]' +
    ', #documentLines > tbody > tr > td > :input[name^="lines["][name$="][retail]"]'
    , 'change', function () {
        var input = $(this);
        var name = input.attr('name');
        var lastproperty = name.split('][')
        var property = lastproperty.pop().slice(0, -1)
        var row = input.parents('tbody');
        row.trigger('multiply', property);
    });

$(document).delegate('#documentLines tbody', 'multiply', function (event, property) {
    var row = $(this);
    var index = row.data('documentLineIndex');
    var quantityInput = $('#documentLines > tbody > tr > td > :input[name^="lines[' + index + '][quantity]"]');
    var quantityInputValue = quantityInput.val();
    var quantityNumeral = numeral(quantityInputValue);

    var priceInput = $('#documentLines > tbody > tr > td > :input[name^="lines[' + index + '][' + property + ']"]');
    var priceInputValue = priceInput.val();
    var priceNumeral = numeral(priceInputValue);

    priceNumeral.multiply(quantityNumeral.value());

    var totalInput = $('#documentLines > tbody > tr > td > :input[name^="lines[' + index + '][total_' + property + ']"]');

    totalInput.val(priceNumeral.value());
    totalInput.trigger('change');
});


// function formatNumber(number) {
//     number = number.toFixed(2) + '';
//     x = number.split('.');
//     x1 = x[0];
//     x2 = x.length > 1 ? '.' + x[1] : '';
//     var rgx = /(\d+)(\d{3})/;
//     while (rgx.test(x1)) {
//         x1 = x1.replace(rgx, '$1' + ',' + '$2');
//     }
//     return x1 + x2;
// }
//
// function updateRefundTotal() {
//     function twoDecimalPlaces(value) {
//         return Math.round(value * 100) / 100;
//     }
//
//     let sum = 0;
//
//     $("#documentLines tbody").each((index, node) => {
//         const deleted = Boolean(node.attributes.getNamedItem("style"));
//         const value = $(`[name='lines[${node.attributes.getNamedItem("data-document-line-index").nodeValue}][total]']`).val();
//         sum += !deleted ? parseFloat(value) : 0;
//     });
//
//     $('[data-total="refund-total"]').innerText()
// }

const updateFunctions = {
    line_total(index) {
        const line = $(`#document-line-${index}`);

        if (line.length) {
            let total = 0;

            const quantity = parseFloat($(`[name="lines[${index}][quantity]"]`).val());
            const price = parseFloat($(`[name="lines[${index}][price]"]`).val());

            // const $tax = $(`[name="lines[${index}][tax]"]`);
            // if ($tax) {
            //     let _price = parseFloat($tax.val());
            //     total = quantity * _price * parseFloat($(`#site-tax`).val());
            // } else {
                total = quantity * price;
            // }

            // console.log(quantity, price, total);

            $(`[name="lines[${index}][total]"]`).val(`${(isNaN(total) ? 0 : total).toFixed(2)}`);
        }

        updateFunctions.sub_total();
    },

    sub_total() {
        const _sub = document.querySelector('[data-total="sub-total"]');
        const _tax = document.querySelector('[data-total="tax"]');
        const _total = document.querySelector('[data-total="total"]');

        let _sum = 0;

        const _lines = document.querySelectorAll('[data-document-line-index]');
        if (!_lines.length) return;

        _lines.forEach(line => {
            if(line.getAttribute("style")
                && line.getAttribute("style").indexOf("display:") != -1
                && line.getAttribute("style").indexOf("none") != -1)
                return;

            const total_elelement = line.querySelector('.update-total');
            if(!total_elelement) return;
            if(!total_elelement.value) return;

            const _total_value = parseFloat(total_elelement.value);
            _sum += isNaN(_total_value) ? 0 : _total_value;
        });

        _total.innerHTML = `R ${_sum.toFixed(2)}`;
        _sub.innerHTML = `R ${(_sum * 0.85).toFixed(2)}`;
        _tax.innerHTML = `R ${(_sum * 0.15).toFixed(2)}`;
    },

    /**
     * Update line total after changing handling fee
     * @param index
     */
    refund_handling(index) {
        const line = $(`#document-line-${index}`);
        if (line.length) {
            const quantity = parseFloat($(`[name="lines[${index}][quantity]"]`).val());
            const price = parseFloat($(`[name="lines[${index}][price]"]`).val());
            let handling = parseFloat($(`[name="lines[${index}][handling]"]`).val());
            if (Number.isNaN(handling)) handling = 0;

            let total = (quantity * price) * ((100 - handling) / 100) * -1;
            let totalDecimal = parseFloat(total).toFixed(2);

            console.log({quantity, price, handling, total, totalDecimal});

            $(`[data-line-total="${index}"]`).html(totalDecimal);
            $(`[name="lines[${index}][total]"]`).val(totalDecimal);

            updateFunctions["refund_total"]();
        }
    },

    refund_total() {
        let refund_total = 0;
        let inv_total = parseFloat($('input[name="inv-total"]').val());

        $("#documentLines tbody").each((index, node) => {
            try {
                const deleted = Boolean(node.attributes.getNamedItem("style"));
                const value = $(`[name='lines[${node.attributes.getNamedItem("data-document-line-index").nodeValue}][total]']`).val();
                refund_total += !deleted ? parseFloat(value) : 0;
            } catch(e) {
                // statements
                console.log(e);
            }
        });

        $('[data-total="refund-total"]').html(`R ${refund_total.toFixed(2)}`);
        $('[data-total="inv-total"]').html(`R ${inv_total.toFixed(2)}`);
        $('[data-total="total"]').html(`R ${(inv_total + refund_total).toFixed(2)}`);
    },
};

/**
 * Generic input update function, adds event listener after page load if element exists
 * Calls a function (see above) to update a specific element
 */
$(':input[data-update-document-line][data-update-document-index]').on('change keyup paste click', function () {
    const inputObject = $(this);
    const documentLine = inputObject.data('updateDocumentLine');
    const documentIndex = inputObject.data('updateDocumentIndex');
    updateFunctions[documentLine](documentIndex);
});

const deleteFunctions = {
    /**
     * Remove line from quote
     * @param index
     */
    quote(index) {
        const line = $(`#document-line-${index}`);
        if (line.length) {
            line.hide();
            $(`:input[name="lines[${index}][delete]"]`).val('on');
            $(`:input[name="lines[${index}][find]"]`).remove();
        }

        updateFunctions["refund_total"]();
    },
};

/**
 * Generic delete function, adds event listener after page load if element exists
 * Calls a function (see above) to remove a specific element
 */
$('button[data-delete-document-line][data-delete-document-index]').on('click', function () {
    const buttonObject = $(this);
    const documentLine = buttonObject.data('deleteDocumentLine');
    const documentIndex = buttonObject.data('deleteDocumentIndex');
    deleteFunctions[documentLine](documentIndex);
    updateFunctions.sub_total();
});

$(function(){
    try {
        updateFunctions.sub_total();
    } catch(e) {
        // statements
        console.log(e);
    }
});

require('./filemanager');
require('./pagination');
require('./notification');
