axios.interceptors.request.use(request => {
    console.log('Starting Request', request)
    return request
});

$(document).delegate('#appNotification .toast', 'hide.bs.toast', function(){
    var toast = $(this);
    var id = toast.data('notificationId');

    var localNotificationString = localStorage.getItem('notification');
    var localNotifications = JSON.parse(localNotificationString);

    $.ajax({
        url: '/user/notification/dismiss/' + id,
        error: function(xhr, status ,err){
            console.log(err);
        },
        success: function(json){

            if(json.success){
                let obj = localNotifications.find(obj => obj.id == id);
                let index = localNotifications.indexOf(obj)
                localNotifications.splice(index,1);
                localStorage.setItem('notification', JSON.stringify(localNotifications));

                var notificationToastSelector = "#appNotification #user-notification-" + id;
                var notificationToast = $(notificationToastSelector);
                notificationToast.remove();
            }
        }
    });
});

$(document).delegate('body', 'display-notifications', function(){

    var appNotification = $('#appNotification');

    var localNotificationString = localStorage.getItem('notification');
    var localNotifications = JSON.parse(localNotificationString);

    var html = '';

    jQuery.each(localNotifications, function(index, notification){
        var template = `
            <div class="toast ml-auto" role="alert" data-delay="700" data-autohide="false" data-notification-id="${notification.id}" id="user-notification-${notification.id}">
              <div class="toast-header">
                <!--<img src="..." class="rounded mr-2" alt="...">-->
                <strong class="mr-auto">[${notification.id}] - ${notification.title}</strong>
                <small class="text-muted">${notification.created_at}</small>
                <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="toast-body">${notification.message}</div>
            </div>
        `
        var notificationToastSelector = "#appNotification #user-notification-" + notification.id;
        var notificationToast = $(notificationToastSelector);

        if( !notificationToast.length ){
            html += template;
        } else {

        }
    });

    appNotification.append(html);
    $('.toast').toast('show');
});

// notifications
if (typeof(Worker) !== "undefined") {
    var notificationWorker;
    if (typeof(notificationWorker) == "undefined") {
        notificationWorker = new Worker('/script/notification.js');
    }

    notificationWorker.onmessage = function(event){
        // okay let's do this
        var localNotificationString = localStorage.getItem('notification');
        var localNotifications = JSON.parse(localNotificationString);
        if(!localNotifications){
            localNotifications = [];
        }

        var data = JSON.parse(event.data);

        jQuery.each(data.notification, function(index, notification){
            var id = notification.id;
            var exist = localNotifications.filter(function (notification) {
                return parseInt(notification.id) == parseInt(id)
            })

            const found = localNotifications.some(el => el.id === id);
            if (!found) localNotifications.push(notification)
        })

        if( localNotifications ){
            localStorage.setItem('notification', JSON.stringify(localNotifications));
            $('body').trigger('display-notifications');
        }
    }

    notificationWorker.onerror = function(event){
        var appNotification = document.getElementById('appNotification');
        appNotification.innerHTML = 'Error!';
    }

    if( typeof ticketPage != "undefined" ){
        var ticketFindQueueWorker;
        if (typeof(ticketFindQueueWorker) == "undefined") {
            ticketFindQueueWorker = new Worker('/script/ticket_find_queue.js');
        }

        ticketFindQueueWorker.onmessage = function(event){
            var json = JSON.parse(event.data);
            jQuery.each(json, function(index, ticket){
                console.log(ticket);
            });
        }

        ticketFindQueueWorker.onerror = function(event){
            var appNotification = document.getElementById('appNotification');
            appNotification.innerHTML = 'Error!';
        }
    } else {
        console.log("Not Page");
    }

} else {
    console.log("Sorry Web Workers not Supported!");
}
